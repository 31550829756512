import { gsap } from "gsap";

(function () {
    //  TODO gsap.fromTo() használata timeline helyett
    const tl = gsap.timeline();

    tl.set("#t-simple", {
        translateY: "50px",
        opacity: 0,
    });
    window.onload = function () {
        // First texts
        tl.to("#t-simple", {
            translateY: 0,
            duration: 1,
            opacity: "100%",
        });
    };
})();
